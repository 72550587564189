import React from 'react'
import { Grid, Hidden, Button, Typography, Container } from '@material-ui/core'
import utils from '../../../helpers/validations'
import Spinner from '../../shared/spinner'
import { history } from '../../../helpers/history'
import ServicesZonesDefinition from './modal'
import ZonesRelations from './zonesRelations'
import AlertDialog from '../../alert-dialog'
import { allowAccessRole } from '../../../helpers/roles'
import { serviceService } from '../../../services/servicesService'
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE } from '../../../constants/types'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'
import { RequestFieldsSection } from './components/requestFieldsSection'
import { HeaderSection } from './components/headerSection'
import { PlannerSection } from './components/plannerSection'
import { PenalitiesSection } from './components/penatiliesSection'




class ServiceFormView extends React.Component {
    constructor(props) {
        super()
        const service = props.service ? props.service.serviceResponse : {}
        const zones = props.service ? props.service.zonesResponse : false
        const authorizedCustomers = props.service ? props.service.authorizedCustomersResponse : false
        const zonesRelations = props.service ? props.service.zoneRelationsResponse : []
        const create = props.nextAction == 'create' ? true : false
        this.state = {
            id: service.id || '',
            name: service.name || '',
            zones: zones || [],
            zonesRelations: zonesRelations,
            otpId: service.otpId ? { id: service.otpId, name: service.otpName } : '',
            authorizedCustomers: authorizedCustomers || [],
            anticipationRequestTime: create ? '' : service.anticipationMinRequestTime,
            anticipationMinType: create ? '' : { id: service.anticipationMinType },
            anticipationRequestTimeMax: create ? '1' : service.anticipationMaxRequestTimeDays,
            notificationDeviationTimeMinutes: create ? '' : service.notificationDeviationTimeMinutes,
            assignedPickupDeviationTimeMinutes: create ? '' : service.assignedPickupDeviationTimeMinutes,
            pickupDeviationTimeMinutes: create ? '' : service.pickupDeviationTimeMinutes,
            cancelTime: create ? '' : service.cancellationTripTimeMinutes,
            additionalPaxs: create ? '' : service.additionalPaxs,
            hasZoneWithStops: service.hasZoneWithStops || false,
            serviceType: create ? '' : { id: service.serviceType },
            assignmentMaxWaitingTimeMinutes: create ? '' : service.assignmentMaxWaitingTimeMinutes,
            restrictionMessageEs: create ? '' : service.restrictionMessageEs,
            restrictionMessageEn: create ? '' : service.restrictionMessageEn,
            restrictionMessageCa: create ? '' : service.restrictionMessageCa,
            modalStops: false,
            modalRestrictions: false,
            editTripTimeMinutes: create ? '' : service.editTripTimeMinutes,
            maxAbsences: create ? '' : service.maxAbsences,
            penaltyDays: create ? '' : service.penaltyDays,
            isRestricted: service.isRestricted || false,
            penaltiesEnabled: create ? true : service.penaltiesEnabled,
            maxTravelTimeFactor: create ? '1' : service.maxTravelTimeFactor,
            minimumAgeAuthorized: create ? '' : service.minimumAgeAuthorized,
            absenceDaysExpiration: create ? '' : service.absenceDaysExpiration,
            showPrice: create ? true : service.showPrice,
            showDriverInfo: create ? false : service.showDriverInfo,
            serviceTimePickUpSeconds: create ? '15' : service.serviceTimePickUpSeconds,
            serviceTimeWheelChairSeconds: create ? '120' : service.serviceTimeWheelChairSeconds,
            serviceTimeLuggageSeconds: create ? '30' : service.serviceTimeLuggageSeconds,
            regions: create ? [] : service.regions,
            municipalities: create ? [] : service.municipalities,
            pickupAndDropOffAutoComplete: create ? false : service.pickupAndDropOffAutoComplete,
            alternativePickupTimeOffset: create ? 0 : service.alternativePickupTimeOffset,
            pricePerDistance: create ? '' : service.pricePerDistance,
            avoidDistanceMatrixCalculationService: create ? false : service.avoidDistanceMatrixCalculationService,
            driverStopDeviationMinutes: create ? 0 : service.driverStopDeviationMinutes,
            openModal: false,
            errors: {
                name: { result: false, message: '' },
                zones: { result: false, message: '' },
                minimumAgeAuthorized: { result: false, message: '' },
                otpId: { result: false, message: '' },
                authorizedCustomers: { result: true, message: '' },
                anticipationMinType: { result: false, message: '' },
                anticipationRequestTime: { result: false, message: '' },
                anticipationRequestTimeMax: { result: false, message: '' },
                notificationDeviationTimeMinutes: { result: true, message: '', },
                assignedPickupDeviationTimeMinutes: { result: true, message: '', },
                pickupDeviationTimeMinutes: { result: true, message: '' },
                assignmentMaxWaitingTimeMinutes: { result: false, message: '' },
                restrictionMessageEs: { result: false, message: '' },
                restrictionMessageEn: { result: false, message: '' },
                restrictionMessageCa: { result: false, message: '' },
                cancelTime: { result: false, message: '' },
                serviceType: { result: false, message: '' },
                maxTravelTimeFactor: { result: false, message: '' },
                editTripTimeMinutes: { result: false, message: '' },
                additionalPaxs: { result: false, message: '' },
                maxAbsences: { result: true, message: '' },
                penaltyDays: { result: true, message: '' },
                absenceDaysExpiration: { result: true, message: '' },
                serviceTimePickUpSeconds: { result: false, message: '' },
                serviceTimeWheelChairSeconds: { result: false, message: '' },
                serviceTimeLuggageSeconds: { result: false, message: '' },
                pricePerDistance: { result: false, message: '' },
                alternativePickupTimeOffset: { result: true, message: '' },
                driverStopDeviationMinutes: { result: true, message: '' },
            },
        }
        if (this.state.zones.length > 0) {
            const errors = this.state.errors
            this.state.zones.forEach(element => {
                errors[element.id] = { result: true, message: '' }
            })
            this.setState({ errors })
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        const { t } = this.props
        let errors = this.state.errors
        errors[name].message = ''
        if (name == 'name' && value) {
            serviceService.serviceHasSameName(this.state.id, value).then(result => {
                if (result) {
                    errors[name] = {
                        result: false,
                        message: t('services.services.SameNameServiceExists'),
                    }
                    this.setState({ errors })
                }
            })
        }
        this.setState({ [name]: value })
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
        })
    }

    onClickBack() {
        history.goBack()
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    validateForm() {
        let errors = this.state.errors
        errors.name = utils.required(this.state.name)
        errors.zones = utils.validateArray(this.state.zones)
        errors.otpId = utils.required(this.state.otpId)
        errors.anticipationMinType = utils.required(this.state.anticipationMinType)
        errors.anticipationRequestTime = utils.minValue(this.state.anticipationRequestTime, 0)
        errors.anticipationRequestTimeMax = utils.minValue(this.state.anticipationRequestTimeMax, 1)
        if (this.state.serviceType?.id != SERVICES_TYPE.regularWithSchedule) {
            errors.notificationDeviationTimeMinutes = utils.minValue(this.state.notificationDeviationTimeMinutes, 0)
            errors.assignedPickupDeviationTimeMinutes = utils.minValue(this.state.assignedPickupDeviationTimeMinutes, 0)
            errors.pickupDeviationTimeMinutes = utils.minValue(this.state.pickupDeviationTimeMinutes, 0)
            errors.alternativePickupTimeOffset = utils.maxValue(this.state.alternativePickupTimeOffset, 240)
        } else {
            errors.notificationDeviationTimeMinutes = { result: true, message: '' }
            errors.assignedPickupDeviationTimeMinutes = { result: true, message: '' }
            errors.pickupDeviationTimeMinutes = { result: true, message: '' }
            errors.alternativePickupTimeOffset = { result: true, message: '' }
        }
        errors.cancelTime = utils.minValue(this.state.cancelTime, 0)
        errors.additionalPaxs = utils.minValue(this.state.additionalPaxs, 0)
        if (this.state.serviceType?.id != SERVICES_TYPE.regularWithSchedule && this.state.penaltiesEnabled) {
            errors.maxAbsences = utils.minValue(this.state.maxAbsences, 0)
            errors.penaltyDays = utils.minValue(this.state.penaltyDays, 0)
            errors.absenceDaysExpiration = utils.minValue(this.state.absenceDaysExpiration, 0)
        } else {
            errors.maxAbsences = { result: true, message: '' }
            errors.penaltyDays = { result: true, message: '' }
            errors.absenceDaysExpiration = { result: true, message: '' }
        }
        errors.editTripTimeMinutes = utils.minValue(this.state.editTripTimeMinutes, 0)
        errors.maxTravelTimeFactor = utils.minValue(this.state.maxTravelTimeFactor, 1)
        errors.maxTravelTimeFactor = utils.maxValue(this.state.maxTravelTimeFactor, 10)
        if (this.state.isRestricted) {
            errors.restrictionMessageEs = utils.required(this.state.restrictionMessageEs)
            errors.restrictionMessageEn = utils.required(this.state.restrictionMessageEn)
            errors.restrictionMessageCa = utils.required(this.state.restrictionMessageCa)
        } else {
            errors.restrictionMessageEs = { result: true, message: '' }
            errors.restrictionMessageEn = { result: true, message: '' }
            errors.restrictionMessageCa = { result: true, message: '' }
        }
        if ([SERVICES_TYPE.assignmentWheelService.toString(), SERVICES_TYPE.regularWithSchedule.toString()].includes(this.state.serviceType?.id)) {
            errors.assignmentMaxWaitingTimeMinutes = utils.minValue(this.state.assignmentMaxWaitingTimeMinutes, 1)
        } else {
            errors.assignmentMaxWaitingTimeMinutes = { result: true, message: '', }
        }
        if (this.state.serviceType?.id == SERVICES_TYPE.regularWithSchedule) {
            errors.pricePerDistance = utils.minValue(this.state.pricePerDistance, 0)
        } else {
            errors.pricePerDistance = { result: true, message: '', }
        }
        errors.serviceType = utils.required(this.state.serviceType)
        errors.minimumAgeAuthorized = utils.minValue(this.state.minimumAgeAuthorized, 0)

        errors.serviceTimePickUpSeconds = utils.minValue(this.state.serviceTimePickUpSeconds, 0)
        errors.serviceTimeWheelChairSeconds = utils.minValue(this.state.serviceTimeWheelChairSeconds, 0)
        errors.serviceTimeLuggageSeconds = utils.minValue(this.state.serviceTimeLuggageSeconds, 0)
        errors.driverStopDeviationMinutes = utils.minValue(this.state.driverStopDeviationMinutes, 0)
        this.setState({ errors })
    }

    onChangeZones = (event, newValue) => {
        let errors = this.state.errors
        const { zoneReducer } = this.props
        errors.zones.message = ''
        newValue.forEach(element => {
            errors[element.id] = { result: true, message: '' }
        })
        this.setState({
            zones: newValue,
            errors,
        })
        this.props.setZones(newValue, zoneReducer.zonesCombination)
    }

    onChangeOtp = (event, newValue) => {
        let errors = this.state.errors
        errors.otpId.message = ''
        this.setState({
            otpId: newValue,
        })
        if (newValue) {
            this.props.nextAction === 'edit' && this.props.setServiceDataEdit(newValue)
        }
    }

    onChangeServiceType = (_, newValue) => {
        let errors = this.state.errors
        errors.serviceType.message = ''
        this.setState({
            serviceType: newValue ?? '',
        })
        if (newValue)
            this.uncheckHasZoneWithStopsInRegularService(newValue?.id)
    }

    handleChangeAnticipation = (_, newValue) => {
        let errors = this.state.errors
        errors.serviceType.message = ''
        this.setState({
            anticipationMinType: newValue ?? '',
        })
    }

    onChangeVehicles = (event, newValue) => {
        let errors = this.state.errors
        errors.vehicles.message = ''
        this.setState({
            vehicles: newValue,
        })
    }

    handleChangeCheckBox = (event) => {
        let { name, checked } = event.target
        let errors = this.state.errors
        this.setState({
            [name]: checked,
            errors
        })
    }

    openModalStops = () => {
        this.setState({ modalStops: true })
    }

    onChangeCustomers = (event, newValue) => {
        let errors = this.state.errors
        errors.authorizedCustomers.message = ''
        this.setState({
            authorizedCustomers: newValue,
        })
    }

    onSubmit = event => {
        if (this.props.detail) {
            this.props.sendService()
        } else {
            event.preventDefault()
            this.validateForm()
            if (this.isFormValid()) {
                this.setState({
                    openModal: true,
                })
            }
        }
    }

    uncheckHasZoneWithStopsInRegularService(serviceType) {
        if (REGULAR_SERVICES_TYPES.includes(serviceType)) {
            this.setState({ hasZoneWithStops: true })
        } else
            this.setState({ hasZoneWithStops: false })
    }

    render() {
        const { t, serviceReducer } = this.props


        return (

            <Container style={{ padding: '20px 30px' }} maxWidth={false}>
                <Spinner loading={serviceReducer.pending} />
                <Typography style={{ fontWeight: 600, marginBottom: '15px' }} variant='h5' component='h2' >{this.props.title}</Typography>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <HeaderSection
                        detail={this.props.detail}
                        handleChange={this.handleChange}
                        onChangeOtp={this.onChangeOtp}
                        onChangeServiceType={this.onChangeServiceType}
                        onChangeZones={this.onChangeZones}
                        onChangeCustomers={this.onChangeCustomers}
                        handleChangeCheckBox={this.handleChangeCheckBox}
                        state={this.state}
                        readOnly={this.props.readOnly}
                        readEdit={this.props.readEdit}
                        nextAction={this.props.nextAction}
                        openModalStops={this.openModalStops}

                    />
                    <RequestFieldsSection
                        handleChange={this.handleChange}
                        readOnly={this.props.readOnly}
                        state={this.state}
                        handleChangeCheckBox={this.handleChangeCheckBox}
                        handleChangeAnticipation={this.handleChangeAnticipation}
                    />

                    <PenalitiesSection
                        handleChange={this.handleChange}
                        handleChangeCheckBox={this.handleChangeCheckBox}
                        readOnly={this.props.readOnly}
                        state={this.state}
                    />

                    <PlannerSection
                        handleChange={this.handleChange}
                        handleChangeCheckBox={this.handleChangeCheckBox}
                        readOnly={this.props.readOnly}
                        state={this.state}

                    />

                    {this.props.detail ? (

                        <ZonesRelations results={this.state.zonesRelations} t={this.props.t} />

                    ) : null}

                    <Grid container spacing={2} style={{ paddingTop: 35 }}>
                        <Grid item md={3} xs={3} sm={3} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} sm={4} xs={4}>
                            <Button variant="contained" color="primary" fullWidth size="large" onClick={this.onClickBack}>
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid item md={1} xs={1} implementation="css" smDown component={Hidden} />
                        {allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage) && (
                            <Grid item md={2} sm={4} xs={4}>
                                <Button variant="contained" color="primary" type="submit" disabled={serviceReducer.pending} fullWidth size="large" >
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        )}
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                    </Grid>


                    <ServicesZonesDefinition
                        t={t}
                        open={this.state.openModal}
                        closeModal={this.handleCloseModal}
                        zones={this.state.zones}
                        state={this.state}
                        {...this.props}
                    />

                    <AlertDialog
                        open={this.state.modalStops}
                        onClickCancel={() =>
                            this.setState({
                                modalStops: false,
                            })
                        }
                        onClickAccept={() =>
                            this.setState({
                                modalStops: false,
                                hasZoneWithStops: !this.state.hasZoneWithStops,
                            })
                        }
                        title={this.state.hasZoneWithStops ? t('services_comp.form.modal.titleUnchecked') : t('services_comp.form.modal.titleChecked')}
                    />

                    <AlertDialog
                        open={this.state.modalRestrictions}
                        onClickCancel={() =>
                            this.setState({
                                modalRestrictions: false,
                            })
                        }
                        onClickAccept={() =>
                            this.setState({
                                modalRestrictions: false,
                                isRestricted: !this.state.isRestricted,
                            })
                        }
                        title={t('services_comp.form.page.shouldCompleteMessages')}
                    />
                </form >
            </Container >

        )
    }
}

export default ServiceFormView
