import React from 'react'
import AlertDialog from '../../alert-dialog'
import Spinner from '../../shared/spinner'
import { Tooltip, IconButton, Box } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import DatePickerFilter from '../../shared/filter/datePicker'
import moment from 'moment'
import MaterialTableExtend from '../../material-table'
import { history } from '../../../helpers/history'
import i18next from 'i18next'
import { CsvBuilder } from 'filefy'
import CancelTripModal from '../../trips/cancelTripModal'
import { TripsIconsInfo } from '../../shared/tripsIcons'


class CustomerTripsView extends React.Component {
    constructor(props) {
        super()
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.setValue = this.setValue.bind(this)
        this.exportCsv = this.exportCsv.bind(this)
    }

    state = {
        openModal: false,
        openModalWheel: false,
        item: {},
        filterPickUp: '',
        filterDropOff: '',
    }
    isReturnSimple = (trip) => ((trip.hasReturn || trip.isReturn) && !trip.isMultipleRequest)

    onClickDetail(trip) {
        const { setTrip, cleanMultipleTripsResults } = this.props
        const { tripId, originalTripId, isMultipleRequest } = trip
        const params = new URLSearchParams({ id: tripId });

        if (this.isReturnSimple(trip)) {
            params.append("withReturn", true)
        }

        if (isMultipleRequest) {
            params.append("originalId", originalTripId)
        }

        if (!isMultipleRequest && !this.isReturnSimple(trip)) {
            setTrip(trip)
            cleanMultipleTripsResults()
        }
        history.push({ pathname: '/resumeTrip', search: params.toString() })
    }

    onClickCancelModal() {
        this.setState({ openModal: false, openModalWheel: false })
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.cancelTrip(this.state.item.tripId)
    }

    onClickOpenModal(data) {
        if (data.status != 64) this.setState({ openModal: true, item: data })
        else this.setState({ openModalWheel: true, item: data })
    }

    onClickBack() {
        history.goBack()
    }

    onClickRepeat(row) {
        const { customerReducer } = this.props
        let customer = row.isOnRelatedCustomerBehalf ? customerReducer.relatedCustomers.filter(x => x.customerIdRelated == row.relatedCustomerId) : customerReducer.relatedCustomers[0]
        this.props.cleanRepeatTrip()
        this.props.repeatTrip(row, customer, false)
        history.push('/requestTrip')
    }

    onClickModify(row) {
        const { customerReducer } = this.props
        let customer = row.isOnRelatedCustomerBehalf ? customerReducer.relatedCustomers.filter(x => x.customerIdRelated == row.relatedCustomerId) : customerReducer.relatedCustomers[0]
        this.props.cleanRepeatTrip()
        this.props.setModifyTrip(row, customer)
        history.push('/requestTrip')
    }

    setValue(prop, values) {
        switch (true) {
            case prop == 'schedulePickUpTime':
                this.setState({
                    filterPickUp: values,
                })
                break
            case prop == 'dropOffTime':
                this.setState({
                    filterDropOff: values,
                })
                break
            default:
                break
        }
        const filters = {
            schedulePickUpTime: prop == 'schedulePickUpTime' ? values : this.state.filterPickUp,
            dropOffTime: prop == 'dropOffTime' ? values : this.state.filterDropOff,
        }
        this.props.getTripsByCustomerId(this.props.customerId, filters)
    }

    onClickCommentsTrips(data) {
        history.push('/customers/trips/observations/' + data.tripId)
    }

    exportCsv(columns, data, table) {
        const { tripReducer } = this.props
        // Turn headers into array of strings
        columns = columns.filter(i => !i.hidden || i.export)
        const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
                /** I am not sure what props the Translate component exposes
                 *  but I would need to change `text` in `col.title.props.text`
                 *  to whatever prop I need
                 */
                return col.title.props.text
            }
            return col.title
        })

        const headerFilter = columns.map(col => {
            if (typeof col.field === 'object' && !col.hidden && col.export) {
                return col.field.props.text
            }
            return col.field
        })

        // Turn data into an array of string arrays, without the `tableData` prop
        const dataRows = data.map(({ tableData, ...row }) => {
            let obj = {}
            let result = []
            for (const element of headerFilter) {
                if (!element.includes("."))
                    obj[element] = row[element]
                else {
                    let splitted = element.split('.')
                    obj[element] = row[splitted[0]][splitted[1]]
                }
            }

            function isDate(dateToTest) {
                return isNaN(dateToTest) && !isNaN(Date.parse(dateToTest));
            }

            Object.entries(obj).forEach(([key, value]) => {

                if (isDate(value)) {
                    value = moment(value).format('DD/MM/YYYY HH:mm')
                }
                if (key == 'status') {
                    value = tripReducer.typesTrips.find(x => x.id == value)?.name || ''
                }
                result.push(value?.toLocaleString())
            })
            return result
        })
        /** Aggregate header data and 'body' data
         *  Mirror default export behavior by joining data via
         *  the delimeter specified within material table (by default comma delimited)
         */
        const { exportDelimiter } = table.props.options
        const delimiter = exportDelimiter || ';'

        /** This mirrors the default export behavior where the
         *  exported file name is the table title.
         */
        const csvFileName = table.props.title

        // Allow user to download file as .csv
        // eslint-disable-next-line no-unused-vars
        const builder = new CsvBuilder(csvFileName + '.csv').setDelimeter(delimiter).setColumns(headerRow).addRows(dataRows).exportFile()
    }

    render() {
        const { tripReducer, t, customerReducer } = this.props
        const obj = {}
        const name = customerReducer.fullName ? customerReducer.fullName : ''

        tripReducer.typesTrips.forEach(element => {
            obj[element.id] = element.name
        })
        const Wrapper = () => (
            <Tooltip title={i18next.t('journeyTrips.page.back')}>
                <IconButton onClick={this.onClickBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
            </Tooltip>
        )
        const lng = i18next.language
        return (
            <React.Fragment>
                {!tripReducer.pending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            title={lng != 'en' ? t('customers.trips.page.title') + name.trim() : name.trim() + "'s " + t('customers.trips.page.title')}
                            buttons={<Wrapper />}
                            columns={[
                                {
                                    title: '',
                                    export: false,
                                    render: (rowData) => {
                                        return (
                                            <TripsIconsInfo rowData={rowData} />
                                        )
                                    }
                                },
                                {
                                    title: t('customers.trips.page.columns.ref'),
                                    field: 'journeyRef',
                                    render: rowData => {
                                        return (
                                            <label>{rowData.journeyRef != '0' ? rowData.journeyRef : ''}</label>
                                        )
                                    },
                                },
                                {
                                    title: t('customers.trips.page.columns.schedulePickUpTime'),
                                    field: 'schedulePickUpTime',
                                    customFilterAndSearch: (term, rowData) => moment(rowData.schedulePickUpTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.schedulePickUpTime ? `${moment(rowData.schedulePickUpTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.pickUpTime'),
                                    field: 'actualPickUpTime',
                                    customFilterAndSearch: (term, rowData) => moment(rowData.actualPickUpTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.actualPickUpTime ? `${moment(rowData.actualPickUpTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.origin'),
                                    field: 'pickUpLocation.address',
                                },
                                {
                                    title: t('customers.trips.page.columns.destination'),
                                    field: 'dropOffLocation.address',
                                },
                                {
                                    title: t('customers.trips.page.columns.passenger'),
                                    field: 'customerName',
                                },
                                {
                                    title: t('customers.trips.page.columns.passengers'),
                                    field: 'numPassengers',
                                },
                                {
                                    title: t('customers.trips.page.columns.dropOffTime'),
                                    field: 'actualDropOffTime',
                                    filterComponent: () => <DatePickerFilter {...this.props} value={this.state.filterDropOff} setValue={this.setValue} prop={'dropOffTime'} />,
                                    render: rowData => (rowData.actualDropOffTime ? `${moment(rowData.actualDropOffTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.scheduleDropOffTime'),
                                    field: 'scheduleDropOffTime',
                                    customFilterAndSearch: (term, rowData) => moment(rowData.scheduleDropOffTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.scheduleDropOffTime ? `${moment(rowData.scheduleDropOffTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.cancelledTime'),
                                    field: 'cancelled',
                                    customFilterAndSearch: (term, rowData) => moment(rowData.cancelled).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.cancelled ? `${moment(rowData.cancelled).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.status'),
                                    field: 'status',
                                    lookup: obj,
                                },
                                {
                                    title: t('customers.trips.page.columns.customerAmount'),
                                    field: 'price.customerGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.holderAmount'),
                                    field: 'price.holderGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.totalAmount'),
                                    field: 'price.totalGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.pickupAndDropoffConcatenated'),
                                    field: 'pickupAndDropoffConcatenated',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.expeditionId'),
                                    field: 'expeditionId',
                                    export: true,
                                    hidden: true
                                },
                            ]}
                            data={tripReducer.resultsTrips}
                            exportCsv={this.exportCsv}
                            actionsColumnIndex={12}
                            actions={[
                                rowData => ({
                                    icon: 'view_list',
                                    tooltip: t('customers.page.actions.detail'),
                                    onClick: (event, rowData) => this.onClickDetail(rowData),
                                    hidden: rowData.pickUpLocation.address == null || rowData.dropOffLocation.address == null,
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: t('customers.trips.page.actions.edit'),
                                    onClick: (event, rowData) => this.onClickModify(rowData),
                                    hidden: !rowData.isModifiable,
                                }),
                                rowData => ({
                                    icon: 'replay',
                                    tooltip: t('customers.trips.page.actions.repeat'),
                                    onClick: (event, rowData) => this.onClickRepeat(rowData),
                                }),
                                rowData => ({
                                    icon: 'note_outlined',
                                    iconProps: {
                                        style: {
                                            color: rowData.hasActivities ? '#80cae9' : '',
                                        },
                                    },
                                    tooltip: t('customers.page.actions.observacions'),
                                    onClick: (event, rowData) => {
                                        this.onClickCommentsTrips(rowData)
                                    },
                                }),
                                rowData => ({
                                    icon: 'delete',
                                    tooltip: t('customers.trips.page.actions.delete'),
                                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                                    hidden: !rowData.isCancellable,
                                }),
                            ]}
                        />
                        <CancelTripModal open={this.state.openModalWheel} itemSelected={this.state.item} cancelTrip={this.props.cancelTrip} cancelTripDriver={this.props.cancelTripByDriverUnavailable} onClickCancel={this.onClickCancelModal} />
                        <AlertDialog open={this.state.openModal} title={t('customers.trips.page.alert_dialog.titleDelete')} onClickAccept={this.onClickAcceptModalDelete} onClickCancel={this.onClickCancelModal} />
                    </React.Fragment>
                ) : (
                    <Spinner loading={tripReducer.pending} />
                )}
            </React.Fragment>
        )
    }
}

export default CustomerTripsView
