import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, FormControlLabel, Checkbox, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import utils from '../../../../helpers/validations'
import { factory } from '../../../../helpers/factory'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '40em',
        maxHeight: '40em',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

class ServicesZonesDefinition extends React.Component {
    constructor(props) {
        super()
        this.state = {
            zones: props.zones,
            change: [],
            zonesSelected: [],
            errors: props.state.errors,
            updateMunicipalitiesAndRegions: false
        }
        this.validateForm = this.validateForm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    validateForm() {
        const { zoneReducer } = this.props
        const zonesCombination = zoneReducer.zonesCombination
        const zonesSelected = zoneReducer.zonesSelected
        let errors = this.state.errors
        zonesSelected.forEach(element => {
            let filter = zonesCombination.filter(i => i.idZone == element.id)
            if (filter.length == 0) {
                errors[element.id] = utils.required('')
            } else {
                errors[element.id] = utils.required(filter[0].zones)
            }
        })
        this.setState({
            errors,
        })
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    onSubmit() {
        this.validateForm()
        if (this.isFormValid()) {
            const state = this.props.state
            const zonesCombination = this.props.zoneReducer.zonesCombination
            const service = factory.createService(
                state.id,
                state.name,
                state.otpId.id,
                state.otpId.name,
                state.zones,
                state.anticipationRequestTime,
                state.anticipationRequestTimeMax,
                state.notificationDeviationTimeMinutes,
                state.pickupDeviationTimeMinutes,
                state.assignedPickupDeviationTimeMinutes,
                state.cancelTime,
                state.maxAbsences,
                state.penaltyDays,
                state.additionalPaxs,
                state.isRestricted,
                state.hasZoneWithStops,
                state.authorizedCustomers,
                state.minimumAgeAuthorized,
                zonesCombination,
                state.editTripTimeMinutes,
                state.serviceType.id,
                state.restrictionMessageEs,
                state.restrictionMessageEn,
                state.restrictionMessageCa,
                state.maxTravelTimeFactor,
                state.absenceDaysExpiration,
                state.anticipationMinType.id,
                state.showPrice,
                state.showDriverInfo,
                state.assignmentMaxWaitingTimeMinutes,
                state.serviceTimePickUpSeconds,
                state.serviceTimeWheelChairSeconds,
                state.serviceTimeLuggageSeconds,
                this.state.updateMunicipalitiesAndRegions,
                state.pickupAndDropOffAutoComplete,
                state.alternativePickupTimeOffset,
                state.pricePerDistance,
                state.penaltiesEnabled,
                state.avoidDistanceMatrixCalculationService,
                state.driverStopDeviationMinutes
            )
            this.props.sendService(service)
            this.props.closeModal()
        }
    }

    onChangeZone = (event, newValue) => {
        let idZone = event.currentTarget.id.split('-')[0]
        if (idZone == '') {
            let array = Array.from(event.currentTarget.parentElement.parentElement.children)
            idZone = array.find(a => a.nodeName == 'INPUT').name
        }
        this.props.setCombination(idZone, newValue)
    }

    onChangeUpdateRegions = (event, newValue) => {
        this.setState({
            updateMunicipalitiesAndRegions: !this.state.updateMunicipalitiesAndRegions,
        })
    }

    render() {
        const classes = this.props.styles
        const { t } = this.props
        const { zoneReducer } = this.props
        const zonesSelected = zoneReducer.zonesSelected
        const zonesCombination = zoneReducer.zonesCombination
        return (
            <Dialog fullWidth open={this.props.open} onClose={this.props.closeModal} maxWidth={'lg'} classes={{ paper: classes.dialogPaper }} TransitionComponent={Transition}>
                <DialogTitle>{t('services_comp.form.page.zonesRelations.title')}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />
                        <Grid item md={6}>
                            {zonesSelected.map(itemZone => (
                                <Autocomplete
                                    id={itemZone.id}
                                    key={itemZone.id}
                                    style={{ padding: 10 }}
                                    value={zonesCombination.find(x => x.idZone == itemZone.id) != null ? zonesCombination.find(x => x.idZone == itemZone.id).values : []}
                                    onChange={this.onChangeZone}
                                    options={zonesSelected}
                                    multiple
                                    getOptionLabel={option => (option.name ? option.name : '')}
                                    renderInput={params => <TextField {...params} variant={'outlined'} label={itemZone.name} helperText={this.state.errors[itemZone.id].message} name={itemZone.id} error={this.state.errors[itemZone.id].message.length === 0 ? false : true} />}
                                />
                            ))}
                        </Grid>
                        <Grid item md={4} implementation="css" smDown component={Hidden} />

                        <Grid item md={4} implementation="css" smDown component={Hidden} />
                        <Grid item md={3}>
                            <Button variant="contained" color="primary" type="button" onClick={this.onSubmit} fullWidth size="large">
                                {this.props.buttonRight}
                            </Button>
                        </Grid>

                        <Grid item md={1} implementation="css" smDown component={Hidden} />

                        <Grid item md={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.updateMunicipalitiesAndRegions}
                                        onChange={this.onChangeUpdateRegions}
                                        disabled={
                                            this.props.readOnly ||
                                            this.props.readEdit
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.zonesRelations.update',)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeModal} color="primary">
                        {t('services_comp.form.page.zonesRelations.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

function Hook(props) {
    const classes = useStyles()
    return <ServicesZonesDefinition styles={classes} {...props} />
}

export default Hook
