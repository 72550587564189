import moment from 'moment'
import i18next from 'i18next'
import store from '../redux/store'
import { tripActions } from '../redux/actions/trip_actions'


export const isObjectEmpty = obj => {
    return Object.keys(obj).length === 0
}

export const calculateTransparentColor = (
    foregroundColor,
    backgroundColor,
    opacity,
) => {
    if (opacity < 0.0 || opacity > 1.0) {
        alert('assertion, opacity should be between 0 and 1')
    }
    opacity = opacity * 1.0 // to make it float
    let foregroundRGB = colorHexToRGB(foregroundColor)
    let backgroundRGB = colorHexToRGB(backgroundColor)
    let finalRed = Math.round(
        backgroundRGB.r * (1 - opacity) + foregroundRGB.r * opacity,
    )
    let finalGreen = Math.round(
        backgroundRGB.g * (1 - opacity) + foregroundRGB.g * opacity,
    )
    let finalBlue = Math.round(
        backgroundRGB.b * (1 - opacity) + foregroundRGB.b * opacity,
    )
    return colorRGBToHex(finalRed, finalGreen, finalBlue)
}

let COLOR_REGEX = /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/
function colorHexToRGB(htmlColor) {
    let arrRGB = htmlColor.match(COLOR_REGEX)
    if (arrRGB == null) {
        alert(
            'Invalid color passed, the color should be in the html format. Example: #ff0033',
        )
    }
    let red = parseInt(arrRGB[1], 16)
    let green = parseInt(arrRGB[2], 16)
    let blue = parseInt(arrRGB[3], 16)
    return { r: red, g: green, b: blue }
}

function colorRGBToHex(red, green, blue) {
    if (
        red < 0 ||
        red > 255 ||
        green < 0 ||
        green > 255 ||
        blue < 0 ||
        blue > 255
    ) {
        alert('Invalid color value passed. Should be between 0 and 255.')
    }

    let hexRed = formatHex(red.toString(16))
    let hexGreen = formatHex(green.toString(16))
    let hexBlue = formatHex(blue.toString(16))

    return '#' + hexRed + hexGreen + hexBlue
}

function formatHex(value) {
    value = value + ''
    if (value.length == 1) {
        return '0' + value
    }
    return value
}

export function handleTripError(detailError, parsedError) {
    switch (true) {
        case detailError.includes('RouteNotFound'):
            detailError = i18next.t('services.trips.requestTrip.RouteNotFound')
            break
        case detailError.includes('ServicesNotFound'):
            detailError = i18next.t(
                'services.trips.requestTrip.ServicesNotFound',
            )
            break
        case detailError.includes('ServiceZonesNotFound'):
            detailError = i18next.t(
                'services.trips.requestTrip.ServiceZonesNotFound',
            )
            break
        case detailError.includes('VehiclesNotFound') &&
            detailError.includes('No vehicles available for given trip'):
            detailError = i18next.t(
                'services.trips.requestTrip.VehiclesNotFound',
            )
            break
        case detailError.includes('VehiclesNotFound') &&
            detailError.includes(
                'Only Freelance vehicles available in service',
            ):
            detailError = i18next.t(
                'services.trips.requestTrip.OnlyFreelanceVehiclesAvailable',
            )
            break
        case detailError.includes('VehiclesNotFound') &&
            detailError.includes('Failed to obtain Vehicles data'):
            detailError = i18next.t(
                'services.trips.requestTrip.FailedObtainVehiclesData',
            )
            break
        case detailError.includes('VehiclesWithPassengerCapacityNotFound'):
            detailError =
                i18next.t(
                    'services.trips.requestTrip.VehiclesWithPassengerCapacityNotFound',
                ) + detailError[detailError.length - 1]
            break
        case detailError.includes('VehiclesWithLuggageCapacityNotFound') &&
            detailError.includes('No vehicles available with luggage capacity'):
            detailError =
                i18next.t(
                    'services.trips.requestTrip.VehiclesWithLuggageCapacityNotFound',
                ) + detailError[detailError.length - 1]
            break
        case detailError.includes('VehiclesWithWheelChairCapacityNotFound') &&
            detailError.includes(
                'No vehicles available with wheelchair capacity',
            ):
            detailError = i18next.t(
                'services.trips.requestTrip.VehiclesWithWheelChairCapacityNotFound',
            )
            break
        case detailError.includes('InvalidParameters'):
            detailError = i18next.t(
                'services.trips.requestTrip.InvalidParameters',
            )
            break
        case detailError.includes('RequestOutOfHours'):
            detailError = i18next.t(
                'services.trips.requestTrip.RequestOutOfHours',
            )
            break
        case detailError.includes('RequestOutOfWeekDays'):
            detailError = i18next.t(
                'services.trips.requestTrip.RequestOutOfWeekDays',
            )
            break
        case detailError.includes('DropOffRequestNotAllowed'):
            detailError = i18next.t(
                'services.trips.requestTrip.DropOffRequestNotAllowed',
            )
            break
        case detailError.includes('VehicleOutsideWorkingHours'):
            detailError = i18next.t(
                'services.trips.requestTrip.VehicleOutsideWorkingHours',
            )
            break
        case detailError.includes('NoLineExpeditionWasFound'):
            detailError = i18next.t(
                'services.trips.requestTrip.NoLineExpeditionWasFound',
            )
            store.dispatch(tripActions.openModalExpeditions(parsedError.object))
            break
        case detailError.includes('LineExpeditionNotAvailable'):
            detailError = i18next.t(
                'services.trips.requestTrip.LineExpeditionNotAvailable',
            )
            store.dispatch(tripActions.openModalExpeditions(parsedError.object))
            break
        case detailError.includes('DuplicatedCustomerTrip'):
            //Check if is a duplicated trip after obtain solution
            let canPerform =
                parsedError.object == null || parsedError.object.canPerformTrip
            if (!canPerform) {
                detailError =
                    i18next.t('services.trips.canPerformTripError') +
                    moment(parsedError.object.nextAvailableTime).format('HH:mm')
            } else {
                detailError = i18next.t(
                    'services.trips.requestTrip.DuplicatedCustomerTrip',
                )
            }
            break
        case detailError.includes('UserNotAuthorized'):
            detailError = detailError.replace('UserNotAuthorized', '')
            break
        case detailError.includes('VehiclesInCalendarNotFound'):
            let vehicles = detailError.split('Details:')[1].trim().split(';')
            let alert = ''
            vehicles.forEach(element => {
                alert = alert + element + ' '
            })
            detailError = i18next.t(alert)
            break
        case detailError.includes('InsufficientAnticipationRequestTime'):
            detailError = i18next.t(
                'services.trips.getServices.InsufficientAnticipationRequestTime',
            )
            break
        case detailError.includes('ExceededAnticipationRequestTime'):
            detailError = i18next.t(
                'services.trips.getServices.ExceededAnticipationRequestTime',
            )
            break
        case detailError.includes('ServiceNotWorkingThisDay'):
            detailError = i18next.t(
                'services.trips.getServices.ServiceNotWorkingThisDay',
            )
            break
        case detailError.includes('ServiceNotWorkingThisDayOfWeek'):
            detailError = i18next.t(
                'services.trips.getServices.ServiceNotWorkingThisDayOfWeek',
            )
            break
        case detailError.includes('ServiceNotWorkingThisMoment'):
            detailError = i18next.t(
                'services.trips.getServices.ServiceNotWorkingThisMoment',
            )
            break
        case detailError.includes('ServiceOutOfWorkingBankHolidays'):
            detailError = i18next.t(
                'services.trips.getServices.ServiceOutOfWorkingBankHolidays',
            )
            break
        case detailError.includes('TripHasReturn'):
            detailError = i18next.t(
                'services.trips.requestTrip.TripHasReturn',
            )
            break
        case detailError.includes('ReturnTripInvalidDate'):
            detailError = i18next.t(
                'services.trips.requestTrip.ReturnTripInvalidDate',
            )
            break
        case detailError.includes('TripNotPossible'):
            detailError = i18next.t('services.trips.requestTrip.TripNotPossible')
            break
        case detailError.includes('InsufficientAnticipationRequestTime'):
            detailError = i18next.t('services.trips.requestTrip.InsufficientAnticipationRequestTime')
            break

        default:
            break
    }
    return detailError
}

export function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}

export function getDropOffLocationAddress(trip) {
    return {
        desc: {
            description: trip.dropOffLocation.address,
        },
        location: {
            address: trip.dropOffLocation.address,
            desc: {
                description: trip.dropOffLocation.address,
            },
            lat: trip.dropOffLocation.lat,
            lng: trip.dropOffLocation.lon,
        }
    }
}

export function getPickUpLocationAddress(trip) {
    return {
        desc: {
            description: trip.pickUpLocation.address,
        },
        location: {
            address: trip.pickUpLocation.address,
            desc: {
                description: trip.dropOffLocation.address,
            },
            lat: trip.pickUpLocation.lat,
            lng: trip.pickUpLocation.lon,
        },
    }
}
