import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import i18next from 'i18next'
import React from 'react'
import { InputNumberWithLabel } from './inputNumberWithLabel'
import SectionStyled, { TitleSection } from './section'
import { Autocomplete } from '@material-ui/lab'
import { useSelector } from 'react-redux'



export const RequestFieldsSection = ({ state, readOnly, handleChange, handleChangeCheckBox, handleChangeAnticipation }) => {

    const anticipationTypes = useSelector((state) => state.serviceReducer.anticipationTypes)

    return (
        <SectionStyled>
            <TitleSection>{i18next.t('services_comp.form.page.titleRequest')}</TitleSection>

            <Grid container spacing={3}>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"additionalPaxs"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.additionalPaxs.message.length > 0}
                        helperText={state.errors.additionalPaxs.message}
                        value={state.additionalPaxs}
                        min={0}
                        max={50}
                        label={i18next.t('services_comp.form.page.additionalPaxs')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.additionalPaxs')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"anticipationRequestTimeMax"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.anticipationRequestTimeMax.message.length > 0}
                        helperText={state.errors.anticipationRequestTimeMax.message}
                        value={state.anticipationRequestTimeMax}
                        min={0}
                        label={i18next.t('services_comp.form.page.anticipationRequestTimeMax')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.anticipationRequestTimeMax')}
                    />
                </Grid>
                <Grid item md={6} xl={3} sm={9} xs={12}>
                    <Autocomplete
                        id="anticipationMinType"
                        value={state.anticipationMinType || ''}
                        onChange={handleChangeAnticipation}
                        options={anticipationTypes}
                        getOptionSelected={(option, value) => option.id == value.id || option.id == value}
                        getOptionLabel={(option) => option.name || anticipationTypes.find((item) => item.id == state.anticipationMinType?.id)?.name || ""}
                        disabled={readOnly}
                        renderInput={params => <TextField {...params} variant={'outlined'} label={i18next.t('services_comp.form.page.anticipationMinType')} helperText={state.errors.anticipationMinType.message} name="otpId" error={state.errors.anticipationMinType.message.length > 0} />}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"minimumAgeAuthorized"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.minimumAgeAuthorized.message.length > 0}
                        helperText={state.errors.minimumAgeAuthorized.message}
                        value={state.minimumAgeAuthorized}
                        min={0}
                        label={i18next.t('services_comp.form.page.minimumAgeAuthorized')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.minimumAgeAuthorized')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"anticipationRequestTime"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.anticipationRequestTime.message.length > 0}
                        helperText={state.errors.anticipationRequestTime.message}
                        value={state.anticipationRequestTime}
                        min={0}
                        label={i18next.t('services_comp.form.page.anticipationRequestTime')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.anticipationRequestTime')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"cancelTime"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.cancelTime.message.length > 0}
                        helperText={state.errors.cancelTime.message}
                        value={state.cancelTime}
                        min={0}
                        label={i18next.t('services_comp.form.page.cancelTime')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.cancelTime')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"editTripTimeMinutes"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.editTripTimeMinutes.message.length > 0}
                        helperText={state.errors.editTripTimeMinutes.message}
                        value={state.editTripTimeMinutes}
                        min={0}
                        label={i18next.t('services_comp.form.page.editTripTimeMinutes')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.editTripTimeMinutes')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.showPrice}
                                name={"showPrice"}
                                disabled={readOnly}
                                onChange={handleChangeCheckBox}
                                color="primary"
                            />
                        }
                        label={i18next.t('services_comp.form.page.showPrice')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.showDriverInfo}
                                name={"showDriverInfo"}
                                disabled={readOnly}
                                onChange={handleChangeCheckBox}
                                color="primary"
                            />
                        }
                        label={i18next.t('services_comp.form.page.showDriverInfo')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.pickupAndDropOffAutoComplete}
                                name={"pickupAndDropOffAutoComplete"}
                                disabled={readOnly}
                                onChange={handleChangeCheckBox}
                                color="primary"
                            />
                        }
                        label={i18next.t('services_comp.form.page.pickupAndDropOffAutoComplete')}
                    />
                </Grid>
            </Grid>
        </SectionStyled>

    )
}