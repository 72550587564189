import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, IconButton } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import React from 'react';
import PropTypes from 'prop-types';
import { blue } from '@material-ui/core/colors';
import LockIcon from '@material-ui/icons/Lock';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import CloseIcon from '@material-ui/icons/Close';
import { alertActions } from '../../../redux/actions/alert_actions';
import store from '../../../redux/store'
import i18next from 'i18next';
import { SERVICES_TYPE } from '../../../constants/types';

class ModalSelectServices extends React.Component {

    handleListItemClick = (item, trip, customerId, noRestriction, isRetry) => {
        this.props.handClose()
        if (!item.isRestricted) {
            if (item.serviceType != 10)
                this.props.setServiceNotServiceLine(item, trip, customerId, noRestriction, isRetry)
            else
                this.props.setServiceServiceLine(item, trip, customerId)
        } else {
            let lang = i18next.language
            store.dispatch(alertActions.warning(Object.values(item['restrictionMessage' + (lang.charAt(0).toUpperCase() + lang.slice(1))])))
        }
    };

    render() {
        const { t, tripReducer, customerReducer, noRestriction } = this.props
        const isRetry = tripReducer.requestTripError
        return (
            <React.Fragment>
                <Dialog onClose={this.props.handClose} aria-labelledby="simple-dialog-title" open={this.props.open}>
                    <DialogTitle>{t('trips.form.modal.title')}
                        {this.props.handClose ? (
                            <IconButton
                                aria-label="close"
                                onClick={this.props.handClose}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}

                    </DialogTitle>
                    <List>
                        {tripReducer.services?.map((item) => (
                            <ListItem button onClick={() => this.handleListItemClick(item, tripReducer.requestTrip, customerReducer.currentCustomer, noRestriction, isRetry)} key={Math.random()}>
                                <ListItemAvatar key={Math.random()}>
                                    <Avatar key={Math.random()} style={{
                                        backgroundColor: blue[100],
                                        color: blue[600],
                                    }}>
                                        {
                                            item.serviceType == SERVICES_TYPE.tadService || item.serviceType == SERVICES_TYPE.assignmentWheelService ?
                                                <LocalTaxiIcon />
                                                :
                                                <AirportShuttleIcon />
                                        }

                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.name} />
                                {
                                    item.isRestricted ?
                                        <LockIcon color='primary' /> : null

                                }
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </React.Fragment>
        );
    }

}

ModalSelectServices.propTypes = {
    handClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ModalSelectServices;