import React from 'react'
import { TripStatus } from '../../../../constants/types'
import { Button, Grid } from '@material-ui/core'
import i18next from 'i18next'
import { useSelector } from 'react-redux'


export const ResumeTripActions = ({ trip, onRejectClick, onAcceptClick, onReturnTrip, handleOnClickPlan }) => {

    const tripReducer = useSelector((state) => state.tripReducer)
    const inMultipleTripScheduleReturn = useSelector((state) => state.multipleTripReducer.areInReturnProcess)

    return (
        <Grid container={inMultipleTripScheduleReturn ?? undefined} item md={inMultipleTripScheduleReturn ? 12 : 3} sm={6} xs={12} justify={inMultipleTripScheduleReturn ? 'flex-end' : undefined}>
            <AcceptRejectButtons
                trip={trip}
                onAcceptClick={onAcceptClick}
                onRejectClick={onRejectClick}
                inMultipleTripScheduleReturn={inMultipleTripScheduleReturn}
                tripPending={tripReducer.pending}
            />
            <RepeatScheduleButtons
                trip={trip}
                handleOnClickPlan={handleOnClickPlan}
                onReturnTrip={onReturnTrip}
                inMultipleTripScheduleReturn={inMultipleTripScheduleReturn}
                tripPending={tripReducer.pending}
            />

        </Grid>
    )
}




const RepeatScheduleButtons = ({ trip, onReturnTrip, handleOnClickPlan, inMultipleTripScheduleReturn, tripPending }) => {

    return (
        <>
            {[
                TripStatus.AcceptedByCustomerService,
                TripStatus.AcceptedByUser,
                TripStatus.AcceptedByIVR,
                TripStatus.AcceptedByThirdParty,
                TripStatus.AcceptedByDriver,
                TripStatus.DriverAssignedByOperator,
                TripStatus.DriverAssignedAfterCompaction
            ].includes(trip.status) && !inMultipleTripScheduleReturn ? (
                <>
                    {!trip.isReturn && !trip.hasReturn &&
                        <Grid item style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={tripPending}
                                onClick={() => onReturnTrip(trip)}
                            >
                                {i18next.t('trips.resume.repeat')}
                            </Button>
                        </Grid>
                    }

                    <Grid item style={{ padding: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={tripPending}
                            onClick={() => handleOnClickPlan(trip)}
                        >
                            {trip.isReturn || trip.hasReturn ? i18next.t('trips.resume.programTripOutboundAndReturn') : i18next.t('trips.resume.programTrip')}
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    )
}


const AcceptRejectButtons = ({ trip, onRejectClick, onAcceptClick, inMultipleTripScheduleReturn, tripPending }) => {
    return (
        <>
            {trip.status == TripStatus.Available ? (
                <React.Fragment>
                    <Grid item xl={inMultipleTripScheduleReturn ? 3 : 6} md={inMultipleTripScheduleReturn ? 6 : 10} sm={11} xs={12} style={{ padding: 10 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled={tripPending}
                            onClick={() => onRejectClick(trip.tripId)}
                        >
                            {i18next.t('trips.resume.reject')}
                        </Button>
                    </Grid>
                    <Grid item xl={inMultipleTripScheduleReturn ? 3 : 6} md={inMultipleTripScheduleReturn ? 6 : 10} sm={11} xs={12} style={{ padding: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={tripPending}
                            onClick={() => onAcceptClick(trip.tripId)}
                        >
                            {i18next.t('trips.resume.accept')}
                        </Button>
                    </Grid>
                </React.Fragment>
            ) : null}
        </>
    )
}